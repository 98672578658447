import React from "react";

function AboutPage() {
  
    return (
      <div>
        <h1>About Page</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Voluptatibus, quod?
        </p>
      </div>
    );
  }
  
  export default AboutPage;